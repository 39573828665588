/**
 * Created by xiongxiaomeng.
 * 报告相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('report/info', params)},
    list: params => {  return API.GET('report/list', params)},
    get: params => {  return API.GET('report/get', params)},
    delete: params => { return API.DELETE('report/delete', params) },
    download: params => { return API.POST('report/download', params) },
    review: params => {  return API.POST('report/review', params)},
}
