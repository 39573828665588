/**
 * Created by xiongxiaomeng.
 * 订单相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('order/info', params)},
    list: params => {  return API.GET('order/list', params)},
    get: params => {  return API.GET('order/get', params)},
    delete: params => { return API.DELETE('order/delete', params) },
    // cancel: params => { return API.POST('order/cancel', parms) },
    complete: params => { return API.POST('order/complete', params) },
    // complete: params => { return API.POST('order/complete', params) },
    inprogress: params => { return API.POST('order/inprogress', params) },
    createByCustomerIdAndProjectIds: params => { return API.POST('order/createByCustomerIdAndProjectIds', params) },
//    createByCustomerIdAndPlanId: params => { return API.POST('order/createByCustomerIdAndPlanId', params) },
    deleteDetailByOrderIdAndDetailId: params => { return API.POST('order/deleteDetailByOrderIdAndDetailId', params) },
    startVerify: params => { return API.POST('order/startVerify', params) },
    startCollect: params => { return API.POST('order/startCollect', params) },

    dispatchDept: params => { return API.POST('order/dispatchDept', params) },
    dispatchUser: params => { return API.POST('order/dispatchUser', params) },
    
}
